<template>
  <div class="registerWrap">
    <div class="mainCom w1200 clearfix">
      <div class="mainComL">
        <menuWX></menuWX>
      </div>
      <div class="mainComR">
        <div class="userInfoWrap mb20" :style="{ backgroundImage: 'url(' + registerBg + ')' }">
          <div class="userInfoBar">
            <div class="title mb20"><img :src="title" alt="快速注册"></div>
            <div class="userInfo">
              <el-form ref="registerForm" :model="registerForm" :rules="registerFormRules" label-width="106px">
                <input type="text" style="position: absolute;z-index:-999;left:-99999px;" />
                <input type="password" style="position: absolute;z-index:-999;left:-99999px;" />
                <el-form-item label="游戏账号:" prop="username"><el-input v-model="registerForm.username" maxlength="12"
                    placeholder="由6~12位数字或字母组成"></el-input></el-form-item>
                <el-form-item label="登录密码:" prop="password"><el-input type="password" v-model="registerForm.password"
                    maxlength="18" placeholder="由6~18位数字、字母、字符组成"></el-input></el-form-item>

                <el-form-item label="绑定手机:" class="telWrap" prop="mobile">
                  <el-select @change="changeArea(registerForm.area)" class="Sel" v-model="registerForm.area"
                    placeholder="Select" style="width: 13%">
                    <el-option style="width: 250px;" v-for="item in cities" :key="item.label" :label="item.value"
                      :value="item.valuee">
                      <span style="float: left">{{ item.label }}</span>
                      <span
                        style="float: right;color: var(--el-text-color-secondary);font-size: 13px;margin-right: 40px;">
                        {{ item.value }}
                      </span>
                    </el-option>
                  </el-select>
                  <el-input style="width: 29%;" ref="mobile" v-model="registerForm.mobile" :maxlength="mobilemaxlength"
                    placeholder="请输入手机号码"></el-input>
                </el-form-item>

                <div class="telTip">请牢记该手机号码，注册成功后为绑定手机，可在账户中心修改！</div>
                <el-form-item label="短信验证:" class="codeWrap" prop="yzm">
                  <div class="codeBar">
                    <el-input v-model="registerForm.yzm" maxlength="6" placeholder="验证码"></el-input>
                    <span class="sendBtn" @click="getCode" v-if="!isSendCode">{{ yzmTxt }}</span>
                    <span class="sendBtn sendBtn1" v-else>{{ timerCount }}s</span>
                  </div>
                </el-form-item>
                <el-form-item label="邀请码:" prop="inviteCode"><el-input v-model="registerForm.inviteCode"
                    placeholder="非必填"></el-input></el-form-item>
                <el-form-item label="真实姓名:" prop="realname"><el-input v-model="registerForm.realname" maxlength="15"
                    placeholder="请输入真实姓名"></el-input></el-form-item>
                <el-form-item label="身份证号:" prop="idcard"><el-input v-model="registerForm.idcard" maxlength="18"
                    placeholder="请输入身份证号码"></el-input></el-form-item>
                <el-form-item label="大区选择:" class="regionSelectionWrap" prop="gameArea"><el-input
                    v-model="registerForm.gameArea" :disabled="true" placeholder="你的名字"></el-input></el-form-item>
                <!--<el-form-item label="大区选择:" class="regionSelectionWrap" prop="gameArea">
                  <el-select v-model="registerForm.gameArea" placeholder="你的名字（大区）">
                    <el-option label="区域一" value="shanghai"></el-option>
                    <el-option label="区域二" value="beijing"></el-option>
                  </el-select>
                </el-form-item>-->
                <el-form-item label="游戏昵称:" prop="usernick"><el-input v-model="registerForm.usernick"
                    placeholder="请输入游戏昵称"></el-input></el-form-item>
                <el-form-item label="游戏性别:" class="sexWrap" prop="sex">
                  <el-select v-model="registerForm.sex" width="100px" placeholder="请选择性别">
                    <el-option label="男" value="1"></el-option>
                    <el-option label="女" value="2"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="readWrap" prop="isRead">
                  <el-checkbox v-model="registerForm.isRead">我已仔细阅读 </el-checkbox>
                  <span class="agreement" @click="goDetail(3)">《舞侠OL用户协议》</span>
                </el-form-item>
                <el-form-item class="submitWrap">
                  <el-button class="registerBtn" type="primary" @click="submitForm('registerForm')">立即注册</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
        <div class="downCons bgWhite">
          <downItemWX :type="2"></downItemWX>
        </div>
      </div>
    </div>
    <pubMask v-if="isShowPask" :isShowPask="isShowPask" :propShowSwitch="showSwitch" :title="maskTitle"></pubMask>
    <!-- 注册成功弹窗 -->
    <div id="mask" v-if="ispopUp">
      <div
        style="position: fixed; top: 13%;left: 35%;border-radius: 10px; width: 30vw; height: 70vh;min-width: 600px; padding: 5px;background: linear-gradient(#D7B7FF, #F9F8FF); z-index: 9999;">
        <popUp @ispopUp="TzpopUp" :propShow=propShow :registerForm=registerForm></popUp>
      </div>
    </div>
  </div>
</template>




<script>
import menuWX from '@/components/Menu'
import downItemWX from '@/views/downLoad/item'
import axios from "axios";
import pubMask from '@/components/pubMask'
import Cookies from "js-cookie";
import popUp from '@/components/pop_up'
export default {
  name: 'register',
  components: {
    menuWX,
    pubMask,
    downItemWX,
    popUp
  },
  data() {
    return {
      ispopUp: false,

      registerBg: require('@/assets/imgs/register/registerBg.png'),
      title: require('@/assets/imgs/register/title.png'),
      gameAreaList: [],
      timer: null,
      timerCount: 60,
      isSendCode: false,
      yzmTxt: '免费获取',
      isShowPask: false,          // 是否显示遮罩层
      showSwitch: 6,
      maskTitle: '',              // 弹窗的title
      yuanMobile: '',             // 修改手机号弹窗带着原手机号
      registerForm: {
        area: '86',
        username: '',
        password: '',
        mobile: '',
        yzm: '',
        inviteCode: '',
        realname: '',
        idcard: '',
        gameArea: '你的名字',   // 大区选择
        usernick: '',    // 昵称
        sex: '请选择性别',    // 性别
        isRead: false,
      },
      mobilemaxlength:11,
      cities: [
        {
          value: '+86',
          valuee: '86',
          label: '中国大陆',
        },
        {
          value: '+886',
          valuee: '886',
          label: '中国台湾',
        },
        {
          value: '+852',
          valuee: '852',
          label: '中国香港',
        },
        {
          value: '+853',
          valuee: '853',
          label: '中国澳门',
        }
      ],
      registerFormRules: {
        username: [
          { required: true, message: '请输入游戏账号', trigger: 'blur' },
          { required: true, pattern: /^([0-9A-Za-z]){6,12}$/, message: '游戏账号由6~12位数字或字母组成', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { required: true, pattern: /^[\w!@#$%^&*()-+=<>?]{6,18}$/i, message: '密码由6~18位数字、字母、字符组成', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { required: true, pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        yzm: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { required: true, pattern: /^[0-9]{6}$/, message: '请输入正确的验证码', trigger: 'blur' }
        ],
        realname: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
          { required: true, pattern: /^[\u4e00-\u9fa5·]{2,15}$/, message: '真实姓名由2个或以上汉字组成', trigger: 'blur' }
        ],
        idcard: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          { required: true, pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请输入正确的身份证号', trigger: 'blur' }
        ],
        usernick: [
          { required: true, message: '请输入游戏昵称', trigger: 'blur' },
          { required: true, pattern: /^.{2,10}$/, message: '游戏昵称由2-10位字符组成', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '请选择您的性别', trigger: 'blur' },
          { required: true, pattern: /^[12]$/, message: '请选择您的性别', trigger: 'blur' }
        ],
      },
      propShow: 1,
    }
  },
  created() {
    document.documentElement.scrollTop = 0
    this.getAameAreaList()
  },
  mounted() {
    this.init()
  },
  methods: {
    goDetail(type) {
      this.$router.push('/gameDetail?type=' + type)
    },          // 查看用户协议
    isMobile(mobile) {
      if (/^1[3456789]\d{9}$/.test(mobile)) {
        return true
      } else {
        return false
      }
    },        // 验证是否是手机号
    countDown() {
      if (this.timerCount == 0) {
        this.timerCount = 60
        this.isSendCode = false
        this.yzmTxt = '重新获取'
        window.clearInterval(this.timer); // 清除定时器
        this.timer = null;
      } else {
        this.timerCount--
      }
    },             // 倒计时
    getCode() {
      // let _this = this
      if (this.registerForm.area != '86') {
        let area = this.areaCode(this.registerForm.mobile)
        if (area) {
          this.changeCode(this.registerForm.area);
        }
      } else {
        console.log(this.registerForm.area);
        if (this.registerForm.mobile == '' || !this.isMobile(this.registerForm.mobile)) {
          this.$refs.mobile.focus()
          this.$refs.mobile.blur()
        } else {
          console.log(this.registerForm.mobile);
          this.changeCode('86');
        }
      }

    },               // 获取验证码

    // 获取验证码封装
    changeCode(area) {
      let _this = this
      window.nvc.getNVCValAsync(function (nvcVal) {
        console.log(nvcVal);
        let data = {
          mobile: _this.registerForm.mobile,
          type: 1,       // 短信类型；1：注册，2：找回密码，3：找回密码 4：修改手机号,5：新绑定手机号
          nvcVal: nvcVal,
          area:area
        }
        axios.post('/sms/sendMsg', data).then((res) => {
          if (res.data.state == 200) {
            _this.$message.success(res.data.message)
            _this.isSendCode = true
            _this.timer = setInterval(_this.countDown, 1000);
            _this.business_handle(res);
          } else {
            _this.$message.error(res.data.message)
          }
        }).catch(err => {
          _this.$message.error(err.message)
        })
      });
    },
    // 港澳台区改变手机规则
    changeArea(area) {
      if (area == '86') {
        this.registerFormRules.mobile[1].pattern = /^1[3456789]\d{9}$/
        this.mobilemaxlength = 11
      } else if (area == '886') {
        this.registerFormRules.mobile[1].pattern = /^09\d{8}$/;
        this.mobilemaxlength = 10
      } else if (area == '852') {
        this.registerFormRules.mobile[1].pattern = /^[4|5|6|8|9]\d{7}$/;
        this.mobilemaxlength = 8
      } else if (area == '853') {
        this.registerFormRules.mobile[1].pattern = /^6\d{7}$/;
        this.mobilemaxlength = 8
      }
      this.registerForm.mobile = ''
      // console.log(this.registerFormRules.mobile[1].pattern, area);
    },
    // 港澳台区号
    areaCode(phoneNumber) {
      // 香港手机号码格式：8位数字，以5、6、8或9开头 
      let hongKongPattern = /^[4|5|6|8|9]\d{7}$/;
      // 澳门手机号码格式：8位数字，以6开头 
      let macauPattern = /^6\d{7}$/;
      // 台湾手机号码格式：10位数字，以09开头 
      let taiwanPattern = /^09\d{8}$/;
      if (hongKongPattern.test(phoneNumber)) {
        return "852";
      }
      else if (macauPattern.test(phoneNumber)) {
        return "853";
      }
      else if (taiwanPattern.test(phoneNumber)) {
        return "886";
      }
      else {
        this.$message.error('不符合港澳台地区手机号码格式')
        return false;
      }
    },


    getAameAreaList() {
    },       // 获取游戏区域下拉
    submitForm(registerForm) {


      let _this = this
      this.$refs[registerForm].validate((valid) => {
        if (valid) {

          if (this.registerForm.isRead) {
            this.propShow = 2
            this.ispopUp = true
          } else {
            this.$message.error('请阅读《舞侠OL用户协议》')
          }
        }
      });
    },
    submit() {
      // console.log( this.registerForm, '注册表单');
      axios.post('/register/doReg', this.registerForm).then((res) => {
        if (res.data.state == 200) {
          // const tishi =this.$message({
          //   type: 'success',
          //   message: res.data.message,
          //   duration: 3000,
          // })
          // setTimeout(()=>{

          // },3000)
          this.$message.success(res.data.message)
          let seconds = 8 * 60 * 60 // 2*60*60;   2小时
          let expires = new Date(new Date() * 1 + seconds * 1000);

          Cookies.set('wx_token', res.data.data.token, { domain: '.qtthtech.com', expires: expires });
          Cookies.set('wx_userId', res.data.data.userId, { domain: '.qtthtech.com', expires: expires });
          /*Cookies.set('wx_token', res.data.data.token, {expires: expires});
          Cookies.set('wx_userId', res.data.data.userId, {expires: expires});*/

          this.resetSetItem('userInfo', JSON.stringify(res.data.data))
          this.ispopUp = false


          if (res.data.data.codeImg != "" && res.data.data.codeImg != undefined) {
            this.maskTitle = res.data.data.codeImg
            this.isShowPask = true;
          } else {
            setTimeout(() => {
              this.$router.go(-1)
            }, 2000)
          }
        } else {
          this.$message.error(res.data.message)
        }

      }).catch(err => {
        this.$message.error(err.message)
      })
    },

    init() {
      let _this = this
      var appKey = 'FFFF0N1N00000000A7D3'
      var scene = 'nvc_message'

      // 实例化nvc 对无痕验证进行初始化操作
      AWSC.use("nvc", function (state, module) {
        console.log(3333, module);
        // 初始化 调用module.init进行初始化
        window.nvc = module.init({
          // 应用类型标识。它和使用场景标识（scene字段）一起决定了无痕验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
          appkey: appKey,
          //使用场景标识。它和应用类型标识（appkey字段）一起决定了无痕验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的scene值，请务必正确填写。
          scene: scene,
          // 二次验证获取人机信息串，跟随业务请求一起上传至业务服务器，由业务服务器进行验签。
          success: function (data) {
            console.log(111);
            window.console && console.log(data)
            _this.verificationData(data, 'nc');
          },
          // 前端二次验证失败时触发该回调参数
          fail: function (failCode) {
            console.log(222);
            window.console && console.log("fail:" + failCode)
          },
          // 前端二次验证加载异常时触发该回调参数。
          error: function (errorCode) {
            console.log(666);
            window.console && console.log("error:" + errorCode)
          }
        });
        console.log(window.nvc);
      });
    },

    verificationData(data, type) {
    },
    // 处理业务返回结果：人机信息串上传接口的回调函数，通过业务服务端的返回结果，控制无痕验证的不同状态。
    business_handle(data) {
      console.log(JSON.stringify(data));
      // 业务服务器请求回调控制是否需要二次验证
      if (data.code == '100' || data.code == '200') {
        // 无痕验证通过
        console.log("验证通过!");
        // 去注册或登录
      } else if (data.code == '800' || data.code == '900') {
        // 无痕验证失败，直接拦截
        console.log("验证失败!");
        // 跳转到账号密码页
        var ncoption = {
          // 声明滑动验证需要渲染的目标ID。
          renderTo: "nc",
          // 二次验证文案配置
          upLang: {
            'cn': {
              //加载状态提示。
              'LOADING': "加载中...",
              //等待滑动状态提示。
              'SLIDE': "请向右滑动验证",
              //验证通过状态提示。
              'SUCCESS': "验证通过",
              //验证失败触发拦截状态提示。
              'ERROR': "非常抱歉，网络出错了...",
              //验证失败触发拦截状态提示。
              'FAIL': "验证失败，请重试"
            }
          }
        }
        // 唤醒二次验证（滑动验证码）
        window.nvc.getNC(ncoption);
      } else if (data.code == '400') {
        // 无痕验证失败，触发二次验证
        // 二次验证码（滑动验证码）配置项设置，详情请见滑动验证集成方式文档
        // 二次验证的appkey，scene，test值及success，fail，error的回调由nvc初始化时决定，请不要在二次验证时传入
        var ncoption = {
          // 声明滑动验证需要渲染的目标ID。
          renderTo: "nc",
          // 二次验证文案配置
          upLang: {
            'cn': {
              //加载状态提示。
              'LOADING': "加载中...",
              //等待滑动状态提示。
              'SLIDE': "请向右滑动验证",
              //验证通过状态提示。
              'SUCCESS': "验证通过",
              //验证失败触发拦截状态提示。
              'ERROR': "非常抱歉，网络出错了...",
              //验证失败触发拦截状态提示。
              'FAIL': "验证失败，请重试"
            }
          }
        }
        // 唤醒二次验证（滑动验证码）
        window.nvc.getNC(ncoption);
      }

    }, // 关闭蒙层
    TzpopUp(msg) {
      console.log(msg, 'dsada');
      if (msg.data == true && msg.type == 2) {
        // this.hintSucceed = true
        this.submit()
      } else if (msg.data == false && msg.type == 2) {
        // this.$message.info('已取消')
      }
      this.ispopUp = false;
    }
  }
}
</script>

<style lang="less">
#mask {
  width: 100%;
  height: 100%;
  // opacity: 0.6;
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 999;
}

.registerWrap {
  .userInfo {
    .el-form {
      padding-left: 240px;
    }

    .el-form-item {
      margin-bottom: 10px;
    }

    .telWrap {
      margin-bottom: 0;

      .Sel {
        .el-input__inner {
          border-right: none !important;
        }
      }

      .el-input__inner {
        width: 100% !important;
      }
    }

    .el-form-item__label {
      font-weight: 600;
      font-size: 18px;

      &:before {
        display: none;
      }
    }

    .el-form-item {
      height: 36px;
      line-height: 36px;
    }

    .el-input__inner {
      padding: 0 6px;
      width: 220px;
      height: 36px;
      line-height: 36px;
      border-radius: 0;
      border: 1px solid #999999;
      background: none;
      box-sizing: border-box;

      &::placeholder {
        font-size: 12px;
      }
    }

    .el-form-item.is-error {
      .el-input__inner {
        border: 2px solid #FF0000;
      }
    }

    .el-input.is-disabled {
      .el-input__inner {
        color: #333333;
      }
    }

    .el-form-item__error {
      top: 9px;
      left: 230px;
    }

    .codeWrap {
      .el-input__inner {
        width: 132px;
      }
    }

    .regionSelectionWrap {
      .el-input__inner {
        width: 120px;
      }
    }

    .sexWrap {
      .el-input__inner {
        width: 130px;
      }
    }

    .readWrap {
      height: 20px;
      line-height: 20px;

      .el-form-item__content {
        margin-left: 0 !important;
        line-height: 20px;

        .el-checkbox__inner {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 1px solid #D22190;

          &:after {
            left: 5px;
            top: 2px;
          }
        }

        .el-checkbox__input.is-checked {
          .el-checkbox__inner {
            background-color: #D22190;
          }
        }

        .el-checkbox__label {
          color: #D22190;
        }
      }

      .agreement {
        cursor: pointer;
        color: #D22190;
      }
    }

    .submitWrap {
      .registerBtn {
        span {
          font-size: 20px;
        }
      }
    }
  }
}
</style>
<style lang="less" scoped>
.registerWrap {
  .userInfoWrap {
    padding: 40px 40px 50px 40px;
    height: 800px;
    box-sizing: border-box;
    background-repeat: no-repeat;

    .userInfoBar {
      .title {
        text-align: center;
      }

      .telTip {
        padding: 7px 0 7px 16px;
        font-size: 12px;
        color: #909090;
      }

      .codeWrap {
        .codeBar {
          display: flex;
          width: 220px;

          .sendBtn {
            display: inline-block;
            justify-content: center;
            align-items: center;
            width: 77px;
            min-width: 77px;
            height: 36px;
            text-align: center;
            color: #fff;
            font-size: 12px;
            background: #333333;
            cursor: pointer;
          }

          .sendBtn1 {
            cursor: default;
          }
        }
      }

      .readWrap {
        margin-bottom: 40px;
      }

      .submitWrap {
        .registerBtn {
          padding: 0;
          width: 220px;
          height: 50px;
          line-height: 50px;
          background: #D22190;
          border: none;
        }
      }
    }
  }

  .downCons {
    padding-top: 50px;
  }
}
</style>
